import en from "./en.json";
import es from "./es.json";
import pt from "./pt.json";
import fr from "./fr.json";
import af from "./af.json";

export const defaultLocale = window.localStorage.getItem("language");

export const languages = {
  en: en,
  es: es,
  pt: pt,
  fr: fr,
  af: af,
};
